import axios from "axios";
import moment from "moment";
import { SpoofHelper } from "./Spoof.Helper";

export class AxiosHelper {
  private static USER_TZ_HEADER_KEY = "x-user-tz";

  public static addCommonAxiosHeaders() {
    SpoofHelper.addCurrentSpoofingToAxiosHeaders();
    let zTz: string = moment().format("Z");
    axios.defaults.headers.common[this.USER_TZ_HEADER_KEY] = zTz;
  }
}
