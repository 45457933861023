import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReviewsAPI } from "../api/Reviews.api";
import CopyClipboardTextArea from "../components/common/CopyCliboard.textarea";
import { ImageComponent } from "../components/common/Image.component";
import { Loader } from "../components/common/Loader.component";
import { Tab, TabView } from "../components/common/Tab.view";
import { Center } from "../components/common/layout/Center.component";
import { ReviewView } from "../components/common/review/Review.view";
import { WindowHelpers } from "../helpers/Window.helper";
import { ImageGeneratorResponse } from "../image/Image.model";
import { MarketingComponent } from "../marketing/Marketing.Component";
import { ReviewV1 } from "../model/Reviews";
import { ReviewShareComponent } from "../share/ReviewShare.component";
import { ShareAchievementsComponent } from "../share/ShareAchievements.component";
import { ShareOfferingsComponent } from "../share/ShareOfferings.component";
import { ShareYIRComponent } from "../share/ShareYIR.Component.";
import { TestUserHelper } from "../test/TestUser.Helper";
import { UserAPI } from "../user/UserAPI";
import { ExpertStudioSharePageTabName } from "./ExpertStudio.model";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface ExpertStudioShareComponentProps extends ExpertStudioContentProps {}

export function ExpertStudioShareComponent(
  props: ExpertStudioShareComponentProps
) {
  let tabs: Tab[] = [
    {
      title: "Announce",
      content: <ExpertStudioShareAnnouncementsComponent user={props.user} />,
      uniqueKey: ExpertStudioSharePageTabName.ANNOUNCEMENT,
      isActive: true,
    },
    {
      title: "Testimonials",
      content: <ExpertStudioShareTestimonialComponent user={props.user} />,
      uniqueKey: ExpertStudioSharePageTabName.TESTIMONIAL,
      isActive: false,
    },
    {
      title: "Offerings",
      content: <ShareOfferingsComponent user={props.user.basicDetails} />,
      uniqueKey: ExpertStudioSharePageTabName.OFFERINGS,
    },
    {
      title: "Achievements",
      content: (
        <ShareAchievementsComponent
          user={props.user.basicDetails}
          initialSelectedAchievementId={WindowHelpers.qParam("uaid")}
        />
      ),
      uniqueKey: ExpertStudioSharePageTabName.ACHIEVEMENTS,
    },
    {
      title: "Year 2024 Review",
      content: <ShareYIRComponent user={props.user.basicDetails} year={2024} />,
      uniqueKey: ExpertStudioSharePageTabName.YEAR_REVIEW,
    },
  ];

  if (TestUserHelper.isTestUser(props.user.basicDetails)) {
    tabs.push({
      title: "Marketing",
      content: <MarketingComponent liu={props.user} />,
      uniqueKey: ExpertStudioSharePageTabName.MARKETING,
    });
  }

  let tabName: ExpertStudioSharePageTabName = WindowHelpers.qParam("share-tab");
  let initalTab = tabs[0];
  for (let tab of tabs) {
    if (tab.uniqueKey == tabName) {
      initalTab = tab;
    }
  }

  return (
    <div className="ExpertStudioShareComponent">
      <h3>
        Share your page and get more bookings{" "}
        <i className="fa-solid fa-bullhorn"></i>
      </h3>
      <p>
        As per the data we have, you will get more bookings if you share your
        profile with your network. We know sharing content is a tedious process.
        But not to worry. We have already created content ready to be posted for
        you. <b>Just copy it and share!</b>
      </p>

      <TabView tabs={tabs} selectedTab={initalTab} />
    </div>
  );
}

export function ExpertStudioShareAnnouncementsComponent(
  props: ExpertStudioShareComponentProps
) {
  let [imageResponse, setImageResponse] = useState<
    ImageGeneratorResponse | undefined
  >();

  useEffect(() => {
    UserAPI.getUserShareableImage(props.user.basicDetails.id).then(
      (imageResponse) => {
        setImageResponse(imageResponse);
      }
    );
  }, []);

  let content =
    "Hey Everyone!," +
    "\n\n🎯 Are you ready to take your personal and professional growth to the next level? 🎯" +
    "\n\n📣 Attention all aspiring individuals, ambitious dreamers, and passionate learners! I am thrilled to announce the launch of my mentorship page on enginEBogie, where I will be sharing my knowledge, experience, and insights to help you achieve your goals and unlock your true potential." +
    "\n\n Connect with me via enginEBogie now: " +
    props.user.basicDetails.profileUrl +
    "\n\nDon't hesitate to reach out if you have any questions or just want to say hi!" +
    "\n\n#enginebogie #mentorship #learning #LearnTogetherGrowTogether";

  return (
    <div className="ExpertStudioShareAnnouncementsComponent fs-4">
      <Row gutter={[20, 20]}>
        {imageResponse && (
          <Col>
            <ImageComponent
              image={imageResponse.image}
              maxWidth={"500px"}
              showDownloadForUrlType={true}
            />
          </Col>
        )}
        <Col className="col-md-6">
          <CopyClipboardTextArea initialValue={content} />
        </Col>
      </Row>
    </div>
  );
}

export function ExpertStudioShareTestimonialComponent(
  props: ExpertStudioShareComponentProps
) {
  let [reviews, setReviews] = useState<ReviewV1[] | undefined>();
  let [selectedReview, setSelectedReview] = useState<ReviewV1 | undefined>();
  let [loading, setLoading] = useState<boolean | undefined>();
  useEffect(() => {
    setLoading(true);
    ReviewsAPI.getReceivedReviews(props.user.basicDetails.id)
      .then((response) => {
        setReviews(response.reviews);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="ExpertStudioShareTestimonialComponent">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && reviews && reviews.length > 0 && (
        <>
          Select a testimonial from below for which you want to create the post:
          <div className="row flex-nowrap overflow-auto bg-light">
            {reviews.map((review, index) => (
              <div className="col-5 p-2" style={{ maxHeight: "250px" }}>
                <a
                  href="javascript:;"
                  className="text-dark"
                  onClick={() => {
                    setSelectedReview(review);
                  }}
                >
                  <div className="card review-card-anchor" key={index}>
                    <div className="card-body ">
                      <ReviewView review={review} />
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </>
      )}
      {!loading && (!reviews || reviews.length == 0) && (
        <>You have not received any testimonials yet to share.</>
      )}
      {selectedReview && (
        <div className="mt-2">
          <ReviewShareComponent review={selectedReview} />
        </div>
      )}
    </div>
  );
}
